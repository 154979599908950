import React from "react"

const testimonials = [
  {
    id: 1,
    name: 'Tr***s',
    comment: `"Thank you for putting this together. I'm limited to mobile most of the day so discord is my only hope for notifications."`
  },
  {
    id: 2,
    name: 'je***in',
    comment: `"Since I've been on your discord I've had 2 opportunities to buy. So you're by far the best one I've seen for getting notifications."`
  },
  {
    id: 3,
    name: 'Q**n',
    comment: `"The world is better with you people."`
  }
]

const Testimonial = ({ name, comment }) => (
  <div className="testimonial">
    <div className="testimonial-body">
      {comment}
    </div>
    <div className="testimonial-name">
      {name}
    </div>
  </div>
)

export default function Testimonials() {
  const testimonialsMarkup = testimonials.map(testimonial => 
    <Testimonial
      key={testimonial.id}
      name={testimonial.name}
      comment={testimonial.comment}
    />
  );
  return (
    <div className="testimonials module">
      <h3>What our users are saying:</h3>
      <div className="testimonials-testimonials">
        {testimonialsMarkup}
      </div>
    </div>
  );
}