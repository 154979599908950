import React from "react"
import Discord from "./discord"
import Disclosure from "./disclosure"

export default function Splash() {
  return (
    <div className="splash">
      <Disclosure />
      <h1>Always know when an item comes back in stock.</h1>
      <Discord />
    </div>
  );
}