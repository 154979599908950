import React from "react"
// import { displayProducts } from "../config/display-products"
import canonR5 from "../images/canon-r5.jpg";
import evgaFtw3 from "../images/evga-ftw3.jpg";
import oculusRift from "../images/oculus-rift.png";
import ps5 from "../images/ps5.jpg";
import rtx3090 from "../images/rtx-3090.png";
import nintendoSwitch from "../images/switch.jpg";
import XBox from "../images/xbox-series-x.png";

const displayProducts = [
  {
    key: 'evga-ftw3',
    name: 'EVGA FTW3',
    alt: 'Image of EVGA FTW3',
    src: evgaFtw3
  },
  {
    key: 'canon-r5',
    name: 'Canon EOS R5',
    alt: 'Image of Canon EOS R5',
    src: canonR5
  },
  {
    key: 'switch',
    name: 'Nintendo Switch',
    alt: 'Image of Nintendo Switch',
    src: nintendoSwitch
  },
  {
    key: 'xbox-series-x',
    name: 'XBOX Series X',
    alt: 'Image of XBOX Series X',
    src: XBox
  },
  {
    key: 'rtx-3090',
    name: 'RTX 3090',
    alt: 'Image of RTX 3090',
    src: rtx3090
  },
  {
    key: 'oculus-rift',
    name: 'Oculus Rift',
    alt: 'Image of Oculus Rift',
    src: oculusRift
  },
  {
    key: 'ps5',
    name: 'Playstation 5',
    alt: 'Image of Playstation 5',
    src: ps5
  },
];

const DisplayProduct = ({ name, alt, src }) => (
  <div className="display-product">
    <img src={src} alt={alt} />
  </div>
)

export default function DisplayProducts() {
  const displayProductsMarkup = displayProducts.map(displayProduct => 
    <DisplayProduct 
      key={displayProduct.key}
      name={displayProduct.name}
      alt={displayProduct.alt}
      src={displayProduct.src}
    />
  );
  return (
    <div className="module display-products">
      <h3>Some of the products we will be tracking:</h3>
      <div className="display-products_products">
        {displayProductsMarkup}
      </div>
    </div>
  );
}