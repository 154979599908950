import React from "react"
import Layout from "../components/layout"
import DisplayProducts from "../components/displayProducts"
import Testimonials from "../components/testimonials"
import Retailers from "../components/retailers"
import Splash from "../components/splash"

const hotTrackers = [
  {
    name: "Nvidia 30 Series GPUs",
    path: "/hot-list/gaming/graphics-cards/nvidia-geforce-rtx-3090",
    shortName: "Nvidia GPUs"
  },
  {
    name: "AMD Radeon 6000 Series GPUs",
    path: "/hot-list/gaming/graphics-cards/amd-radeon-6900-xt",
    shortName: "Radeon GPUs"
  },
  {
    name: "AMD Ryzen 5000 CPUs",
    path: "/hot-list/gaming/desktop-processors/Ryzen5000",
    shortName: "Ryzen CPUs"
  },
  {
    name: "Microsoft Xbox",
    path: "/hot-list/gaming/game-consoles/microsoft-xbox/xbox-series-x",
    shortName: "Microsoft Xbox"
  },
  {
    name: "Sony Playstation",
    path: "/hot-list/gaming/game-consoles/sony-playstation/ps5-digital",
    shortName: "Sony PS5"
  }
];


export default function Home() {
  const title = {
    title: 'HOT TRACKERS',
    symbol: '🔥',
    symbolLabel: 'fire'
  };
  return (
    <Layout relatedPages={hotTrackers} name="home" showBurger={true} title={title}>
      <Splash />
      <div className="home-modules">
        <DisplayProducts />
        <Testimonials />
        <Retailers />
      </div>
    </Layout>
  );
}
