import React from "react"

export default function Discord() {
  return (
    <div className="discord">
      <h2>Join us on Discord. It's free!</h2>
      <div className="discord-widget">
        <div className="discord-widget_iframe">
          <iframe title="Discord Widget" class="responsive-iframe" src="https://discordapp.com/widget?id=764902810075529286&theme=dark" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
        <div className="discord-widget_cta">
          <div className="discord-widget_cta-text">
            Hit that Connect button and you're one emoji reaction away from fast alerts.
          </div>
          <div className="discord-widget_cta-icon">
            <div class="arrow left"></div>
            <div class="arrow up"></div>
          </div>
        </div>
      </div>
    </div>
  );
}