export const retailers = [
  {
    key: 'amazon',
    name: 'Amazon',
    logo: {
      src: '',
      alt: 'Amazon Logo'
    },
    url: 'https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=refreshsystems-20&linkId=aff39d91ad0ef4966111959b06a632ab&language=en_US',
    note: ''
  },
  {
    key: 'bestbuy',
    name: 'Best Buy',
    logo: {
      src: '',
      alt: 'Best Buy Logo'
    },
    url: 'https://bestbuy.7tiv.net/X6N1y',
    note: ''
  },
  {
    key: 'adorama',
    name: 'Adorama',
    logo: {
      src: '',
      alt: 'Adorama Photo Logo'
    },
    url: 'https://adorama.rfvk.net/rVmGQ',
    note: ''
  },
  {
    key: 'newegg',
    name: 'Newegg',
    logo: {
      src: '',
      alt: 'Newegg Logo'
    },
    url: 'https://click.linksynergy.com/deeplink?id=DWHsuOLiKaA&mid=44583&murl=https%3A%2F%2Fwww.newegg.com%2F',
    note: ''
  }
];