import React from "react"
import { retailers } from "../config/retailers"

const Retailer = ({ logo, url, name, note }) => (
  <div className="retailer">
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <span className="link-spanner"></span>
    </a>
    <div className="retailer-name">{name}</div>
    {(note.length > 1) &&
      <div className="retailer-note">{note}</div>
    }
  </div>
)

export default function Retailers() {
  const retailersMarkup = retailers.map(retailer => 
    <Retailer 
      logo={retailer.logo}
      url={retailer.url}
      name={retailer.name}
      note={retailer.note}
      key={retailer.key}
    />
  );
  return (
    <div className="module retailers">
      <h3>Just a few of the retailers we cover</h3>
      <div className="retailers-retailers">
        {retailersMarkup}
      </div>
    </div>
  );
}